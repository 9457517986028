'use client';

import {
  Box,
  Button,
  Divider,
  Stack,
  TextField,
  Typography,
  type TextFieldProps,
} from '@mui/material';
import Link from 'next/link';
import { useSearchParams } from 'next/navigation';
import { useEffect, useState } from 'react';

import { useEnvironment } from '@/contexts/useEnvironment';

export function DomainInput({ inputProps, ...props }: TextFieldProps) {
  return (
    <div className='relative'>
      <TextField
        size='medium'
        placeholder='Add your domain'
        inputProps={{
          ...inputProps,
          style: { height: '36px', paddingRight: 130 },
        }}
        {...props}
      />
      <Typography
        sx={{
          position: 'absolute',
          top: '50%',
          transform: 'translateY(-50%)',
          right: 20,
          width: 100,
        }}
      >
        .console.eon.io
      </Typography>
    </div>
  );
}

export function LoginPage() {
  const [domain, setDomain] = useState('');
  const { clientApiBaseUrl } = useEnvironment();
  const searchParams = useSearchParams();

  const error = searchParams.get('error');

  const getReturnURL = () => {
    const returnUrl = new URL(
      searchParams.get('returnUrl') ?? '',
      window.location.origin
    ).toString();

    return new URLSearchParams({ returnUrl }).toString();
  };

  useEffect(() => {
    if (window.location.href.split('.').length === 4) {
      window.location.href = `${clientApiBaseUrl}/login?${getReturnURL()}`;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onLogin = () => {
    if (!domain) return;
    window.location.replace(
      `${clientApiBaseUrl}/login?domain=${domain}&${getReturnURL()}`
    );
  };

  return (
    <Box className='m-auto'>
      <Typography variant='h1' className='font-extralight text-6xl text-center'>
        Login to Eon
      </Typography>
      <Box className='flex justify-center content-center mt-[24px]'>
        <DomainInput
          inputRef={(input) => input?.focus()}
          inputProps={{ 'data-testid': 'login-domain' }}
          value={domain}
          sx={{ width: '320px' }}
          onChange={(e) => {
            const newValue = e.target.value;

            // allow only alphanumeric characters, hyphens, and periods
            if (/^[a-zA-Z0-9-]*$/.test(newValue)) {
              setDomain(newValue);
            }
          }}
          onKeyUp={(event) => {
            if (event.key === 'Enter') {
              onLogin();
            }
          }}
        />

        <Button
          className='px-[20px] ml-[16px]'
          disabled={!domain}
          data-testid='login-button'
          variant='contained'
          onClick={onLogin}
        >
          Login
          <i className='material-symbols-arrow-right-alt ml-[8px] align-middle' />
        </Button>
      </Box>
      {error && (
        <Typography variant='body1' color='error' data-testid='error-message'>
          <Stack
            className='my-[15px]'
            direction='row'
            alignItems='center'
            justifyContent='center'
          >
            <i className='material-symbols-error-outline-rounded mr-[8px]'></i>
            {error}
          </Stack>
        </Typography>
      )}
      <Divider
        sx={{
          width: 600,
          margin: '40px auto',
          borderColor: 'var(--mui-palette-background-lightGray)',
        }}
      />
      <Box className='text-center'>
        {`Don't have an account? `}
        <Link
          className='font-bold'
          href='https://www.eon.io/get-a-demo'
          prefetch={false}
        >
          Sign Up
        </Link>
      </Box>
    </Box>
  );
}
